import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import ItemList from "../Tasks/sub-components/ItemList";
import ItemComponent from "../Tasks/sub-components/ItemComponent";
import ActionPanel from "../Actions/ActionPanel";
import HashtagAndItemManager from "../Hashtags/sub-components/HashtagAndItemManager";
import Logout from "../Login/Logout";
import UserProfile from "../Login/UserProfile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import "./App.css";
import { copyToClipboard } from "../../utils/clipboardUtils";
import "bootstrap-icons/font/bootstrap-icons.css";
import MobileMainComponent from "./MobileMainComponent";

function MainComponent() {
  const isDesktop = useMediaQuery({ query: "(min-width: 768px)" });
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedHashtags, setSelectedHashtags] = useState(() => {
    const saved = localStorage.getItem("selectedHashtags");
    return saved
      ? JSON.parse(saved)
      : [{ _id: "uncategorized", tag: "Uncategorized" }];
  });
  const fetchControllerRef = useRef(null);
  const [refreshItemList, setRefreshItemList] = useState(false);
  const [dateComment, setDateComment] = useState("");
  const [items, setItems] = useState([]);
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    console.log("Saving selected hashtags to localStorage:", selectedHashtags);
    localStorage.setItem("selectedHashtags", JSON.stringify(selectedHashtags));
  }, [selectedHashtags]);
  useEffect(() => {
    const savedItem = localStorage.getItem("selectedItem");
    if (savedItem) {
      setSelectedItem(JSON.parse(savedItem));
    }
  }, []);
  useEffect(() => {
    if (selectedItem && selectedItem.itemId) {
      const fetchItem = async () => {
        try {
          // Abort any previous API call
          if (fetchControllerRef.current) {
            fetchControllerRef.current.abort();
          }

          const controller = new AbortController();
          fetchControllerRef.current = controller;

          const response = await fetch(`/api/items/${selectedItem.itemId}`, {
            signal: controller.signal,
          });
          if (!response.ok) throw new Error("Failed to fetch item");
          const itemData = await response.json();
          setSelectedItem(itemData);
        } catch (error) {
          if (error.name !== "AbortError") {
            console.error("Error fetching item:", error);
          }
        }
      };

      fetchItem();
    }

    return () => {
      // Clean up fetch controller on unmount or when selectedItem changes
      if (fetchControllerRef.current) {
        fetchControllerRef.current.abort();
      }
    };
  }, [selectedItem?.itemId]);

  // useEffect(() => {
  //   if (selectedItem && selectedItem.itemId) {
  //     const fetchItem = async () => {
  //       try {
  //         const response = await fetch(`/api/items/${selectedItem.itemId}`);
  //         if (!response.ok) throw new Error("Failed to fetch item");
  //         const itemData = await response.json();
  //         setSelectedItem(itemData);
  //       } catch (error) {
  //         console.error("Error fetching item:", error);
  //       }
  //     };
  //     fetchItem();
  //   }

  // }, [selectedItem?.itemId]);

  const handleItemClick = (item) => {
    console.log("Item clicked:", item);
    setSelectedItem(item);
    localStorage.setItem("selectedItem", JSON.stringify(item));
  };

  const handleNewItemAdded = () => {
    setRefreshItemList((prev) => !prev);
  };

  useEffect(() => {
    console.log("Updating local storage with hashtags:", selectedHashtags);
    localStorage.setItem("selectedHashtags", JSON.stringify(selectedHashtags));
  }, [selectedHashtags]);

  if (!isDesktop) {
    return <MobileMainComponent />;
  }

  const handleHashtagSelection = (hashtags) => {
    console.log("Hashtags selected:", hashtags);
    setSelectedHashtags(hashtags);
  };

  const copyHashtagUrl = () => {
    const customIdUrlPart = selectedHashtags.map((ht) => ht.customId).join(",");
    copyToClipboard(`${window.location.origin}/hashtags/${customIdUrlPart}`)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch((err) => console.error("Failed to copy:", err));
  };

  const onItemUpdated = (updatedItem) => {
    setSelectedItem(updatedItem);
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.itemId === updatedItem.itemId ? updatedItem : item
      )
    );
    setRefreshItemList((prev) => !prev);
  };

  function onPioneerClick(event) {
    if (selectedHashtags && selectedHashtags.length > 0) {
      event.preventDefault();
    }
  }

  const shareHashtags = () => {
    const customIdUrlPart = selectedHashtags.map((ht) => ht.customId).join(",");

    window.open(`/hashtags/${customIdUrlPart}`, "_blank");
  };

  return (
    <>
      <div className="top-navbar">
        <Link to="/" onClick={onPioneerClick}>
          <div className="logo">
            <img src="/logo.png" alt="Logo" />
          </div>
        </Link>
        <Link to="/" className="app-title-link" onClick={onPioneerClick}>
          <div className="app-title">Pioneer</div>
        </Link>
        <div className="hashtag-section">
          <div className="hashtags-and-share">
            <div className="selected-hashtags">
              {selectedHashtags.map((hashtag, index) => (
                <p key={index}>{hashtag.tag}</p>
              ))}
            </div>
            <div className="icons-section">
              <button className="share-button" onClick={shareHashtags}>
                <FontAwesomeIcon icon={faShare} />
              </button>

              <div>
                <button
                  onClick={copyHashtagUrl}
                  className="btn  copy-button"
                  title="Copy URL to Clipboard"
                  // style={{ border: "none", marginLeft: "0px" }}
                >
                  <i
                    className={`bi ${
                      copied ? "bi-clipboard-check" : "bi-clipboard"
                    }  icons`}
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="logout-button">
          <Logout />
        </div>
        <div>
          <UserProfile />
        </div>
      </div>

      {selectedItem && (
        <div className="ActionPanel">
          <ActionPanel
            key={selectedItem ? selectedItem.itemId : "none"}
            selectedItem={selectedItem}
            onItemUpdated={onItemUpdated}
          />
        </div>
      )}

      <div className="HashtagItemPanel">
        <HashtagAndItemManager
          selectedHashtags={selectedHashtags}
          setSelectedHashtags={setSelectedHashtags}
          onItemAdded={handleNewItemAdded}
        />
      </div>

      <div className="SidePanel">
        <ItemList
          onItemClick={handleItemClick}
          selectedItem={selectedItem}
          selectedHashtags={selectedHashtags}
          refreshTrigger={refreshItemList}
        />
      </div>

      {selectedItem && (
        <div className="MainContent">
          <ItemComponent
            item={selectedItem}
            onItemUpdated={onItemUpdated}
            dateComment={dateComment} //
          />
        </div>
      )}
    </>
  );
}

export default MainComponent;
