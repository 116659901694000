import React from "react";
import { useSelector } from "react-redux";
import DOMPurify from "dompurify";
import "./CommentList.css";

function CommentList({ itemId, comments }) {
  console.log("CommentList props:", comments);

  function createMarkup(htmlContent) {
    console.log("Original HTML Content:", htmlContent);
    if (!htmlContent) return { __html: "" };

    const sanitizedContent = DOMPurify.sanitize(htmlContent, {
      ALLOWED_TAGS: [
        "b",
        "i",
        "u",
        "strike",
        "blockquote",
        "a",
        "br",
        "p",
        "ul",
        "li",
        "ol",
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "span",
        "img",
        "strong", // Add for bold
        "em", // Add for italic
        "wbr",
      ],
      ALLOWED_ATTRS: { "*": ["style", "class", "href", "src", "alt"] },
    });

    const brokenText = sanitizedContent.replace(/([^><]+)(?=<|$)/g, (match) => {
      return match.replace(/(.{10})(?=.)/g, "$1<wbr>");
    });
    console.log("Sanitized Content:", sanitizedContent);
    return { __html: brokenText };
  }

  function formatDate(dateStr) {
    if (!dateStr) return "Invalid Date";
    const date = new Date(dateStr);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  }

  const commentsFromStore = useSelector(
    (state) => state.comments[itemId] || []
  );

  const safeComments = comments || commentsFromStore;

  return (
    <div className="comments-container">
      {safeComments.map((comment, index) => (
        <div key={comment._id || index} className="comment">
          <small className="comment-serial-number">comment #{index + 1}</small>
          <div
            className="comment-content"
            dangerouslySetInnerHTML={createMarkup(comment.text)}
          />
          <small>
            {comment.postedBy || "Anonymous"} on {formatDate(comment.postedAt)}
          </small>
        </div>
      ))}
    </div>
  );
}

export default CommentList;
