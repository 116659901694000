import React, { useState, useEffect } from "react";
import "../styles/HashtagAndItemManager.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import HashtagService from "../services/HashtagService";

function HashtagManager({
  setSelectedTasks,
  selectedHashtags,
  setSelectedHashtags,
}) {
  const uncategorizedHashtagId = process.env.REACT_APP_UNCATEGORIZED_HASHTAG_ID;
  const [hashtags, setHashtags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [orgs, setOrgs] = useState([]);
  const [teams, setTeams] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  // const [selectedHashtags, setSelectedHashtags] = useState([]);
  const [uiSelectedHashtags, setUiSelectedHashtags] = useState([]);
  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        console.log("Fetching organizations...");
        setLoading(true);
        const orgData = await HashtagService.fetchOrganizations();
        console.log("Organizations fetched successfully:", orgData);
        setOrgs(orgData);
      } catch (err) {
        console.error("Error fetching organizations:", err);
        setError("Failed to fetch organizations.");
      } finally {
        setLoading(false);
      }
    };
    fetchOrganizations();
  }, []);

  const handleOrgSelection = async (event) => {
    const orgId = event.target.value;
    setSelectedOrg(orgId);

    try {
      console.log(`Fetching teams for organization ID: ${orgId}`);
      setLoading(true);
      const teamData = await HashtagService.fetchTeamsByOrg(orgId);
      console.log("Teams fetched successfully:", teamData);
      setTeams(teamData);
    } catch (err) {
      console.error("Error fetching teams:", err);
      setError("Failed to fetch teams.");
    } finally {
      setLoading(false);
    }
  };
  // const handleTeamSelection = async (event) => {
  //   const teamId = event.target.value;
  //   setSelectedTeam(teamId);

  //   try {
  //     console.log(`[INFO] Fetching hashtags for team ID: ${teamId}`);
  //     setLoading(true);

  //     if (teamId === "uncategorized") {
  //       console.log("[INFO] Fetching uncategorized hashtags...");
  //       const uncategorizedHashtags =
  //         await HashtagService.fetchUncategorizedHashtags();
  //       console.log(
  //         "[SUCCESS] Uncategorized hashtags fetched:",
  //         uncategorizedHashtags
  //       );
  //       setHashtags(uncategorizedHashtags);
  //     } else {
  //       const teamHashtags = await HashtagService.fetchHashtagsByTeam(teamId);
  //       console.log("[SUCCESS] Hashtags fetched for team:", teamHashtags);
  //       setHashtags(teamHashtags);
  //     }
  //   } catch (err) {
  //     console.error("[ERROR] Fetching hashtags failed:", err.message);
  //     setError("Failed to fetch hashtags.");
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const handleTeamSelection = async (event) => {
    const teamId = event.target.value;
    setSelectedTeam(teamId);

    try {
      console.log(`[INFO] Fetching hashtags for team ID: ${teamId}`);
      setLoading(true);

      if (teamId === "uncategorized") {
        console.log("[INFO] Fetching uncategorized hashtags...");
        const uncategorizedHashtags =
          await HashtagService.fetchUncategorizedHashtags();
        console.log(
          "[SUCCESS] Uncategorized hashtags fetched:",
          uncategorizedHashtags
        );
        setHashtags(uncategorizedHashtags);
      } else {
        const teamHashtags = await HashtagService.fetchHashtagsByTeam(teamId);
        console.log("[SUCCESS] Hashtags fetched for team:", teamHashtags);
        setHashtags(teamHashtags);
      }
    } catch (err) {
      console.error("[ERROR] Fetching hashtags failed:", err.message);
      setError("Failed to fetch hashtags.");
    } finally {
      setLoading(false);
    }
  };

  // const handleSelectHashtag = (hashtag) => {
  //   setSelectedHashtags((prev) => {
  //     const exists = prev.some((h) => h._id === hashtag._id);
  //     const updatedHashtags = exists
  //       ? prev.filter((h) => h._id !== hashtag._id)
  //       : [...prev, hashtag];

  //     console.log("[INFO] Updated selected hashtags:", updatedHashtags);
  //     return updatedHashtags;
  //   });
  // };

  // const handleTeamSelection = async (event) => {
  //   const teamId = event.target.value;
  //   setSelectedTeam(teamId);

  //   try {
  //     console.log(`Fetching hashtags for team ID: ${teamId}`);
  //     setLoading(true);
  //     const hashtagData = await HashtagService.fetchHashtagsByTeam(teamId);

  //     if (!Array.isArray(hashtagData)) {
  //       throw new Error("Invalid response format for hashtags.");
  //     }

  //     console.log("Hashtags fetched successfully:", hashtagData);
  //     setHashtags(hashtagData);
  //   } catch (err) {
  //     console.error("Error fetching hashtags:", err.message);
  //     setError("Failed to fetch hashtags.");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const handleTeamSelection = async (event) => {
  //   const teamId = event.target.value;
  //   setSelectedTeam(teamId);

  //   try {
  //     console.log(`Fetching hashtags for team ID: ${teamId}`);
  //     setLoading(true);
  //     const hashtagData = await HashtagService.fetchHashtagsByTeam(teamId);
  //     console.log("Hashtags fetched successfully:", hashtagData);
  //     setHashtags(hashtagData);
  //   } catch (err) {
  //     console.error("Error fetching hashtags:", err);
  //     setError("Failed to fetch hashtags.");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   const fetchHashtags = async () => {
  //     try {
  //       setLoading(true);
  //       const fetchedHashtags = await HashtagService.fetchHashtags();
  //       setHashtags(fetchedHashtags);
  //       console.log("Fetched hashtags:", fetchedHashtags);
  //     } catch (err) {
  //       console.error("Error fetching hashtags:", err.message);
  //       setError("Failed to fetch hashtags.");
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  //   fetchHashtags();
  // }, []);
  useEffect(() => {
    const fetchHashtags = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/hashtags`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch hashtags");
        }
        const data = await response.json();
        setHashtags(data);

        // Default to 'Uncategorized' if no hashtags are selected
        if (!uiSelectedHashtags.length) {
          const uncategorized = data.find(
            (h) => h._id === uncategorizedHashtagId
          );
          if (uncategorized) {
            setUiSelectedHashtags([uncategorized]);
            setSelectedHashtags([uncategorized]); // Update parent component
          }
        }
      } catch (err) {
        setError("Failed to fetch hashtags");
      } finally {
        setLoading(false);
      }
    };
    fetchHashtags();
  }, []);

  // Persist the selected hashtags in local storage
  useEffect(() => {
    const savedHashtags = localStorage.getItem("uiSelectedHashtags");
    if (savedHashtags) {
      const parsed = JSON.parse(savedHashtags);
      setUiSelectedHashtags(parsed);
      setSelectedHashtags(parsed); // Update parent component
    }
  }, []);

  // Update local storage when `uiSelectedHashtags` changes
  useEffect(() => {
    localStorage.setItem(
      "uiSelectedHashtags",
      JSON.stringify(uiSelectedHashtags)
    );
    setSelectedHashtags(uiSelectedHashtags); // Update parent component
  }, [uiSelectedHashtags, setSelectedHashtags]);

  const handleSelectHashtag = (hashtag) => {
    setUiSelectedHashtags((prev) => {
      const exists = prev.some((h) => h._id === hashtag._id);
      if (exists) {
        const updatedHashtags = prev.filter((h) => h._id !== hashtag._id);
        return updatedHashtags.length
          ? updatedHashtags
          : [{ _id: uncategorizedHashtagId, tag: "Uncategorized" }]; // Default to 'Uncategorized'
      } else {
        return [
          ...prev.filter((h) => h._id !== uncategorizedHashtagId),
          hashtag,
        ];
      }
    });
  };
  const fetchTasksByHashtags = async (selectedHashtags) => {
    if (!selectedHashtags.length) {
      console.log("No hashtags selected, clearing tasks.");
      setSelectedTasks([]); // Clear tasks
      return;
    }

    try {
      const hashtagIds = selectedHashtags
        .map((hashtag) => hashtag._id)
        .join(",");
      console.log("Fetching tasks for hashtags:", hashtagIds);
      const tasks = await HashtagService.fetchItemsByHashtag(hashtagIds);
      console.log("Tasks fetched successfully:", tasks);
      setSelectedTasks(tasks); // Pass tasks to parent component
    } catch (err) {
      console.error("Error fetching tasks:", err.message);
      setError("Failed to fetch tasks.");
    }
  };

  return (
    <>
      <div className="org-selector-container">
        <div className="org-team-wrapper">
          <div className="org-selector">
            <label htmlFor="org-select" className="label-headings">
              Org:
            </label>
            <select
              id="org-select"
              value={selectedOrg || ""}
              onChange={handleOrgSelection}
              className="org-label-input"
            >
              <option value="">Select Organization</option>
              {orgs.map((org) => (
                <option key={org._id} value={org._id}>
                  {org.name}
                </option>
              ))}
            </select>
          </div>
          <div className="team-selector">
            <label htmlFor="team-select" className="team-headings">
              Team:
            </label>
            <select
              id="team-select"
              value={selectedTeam || ""}
              onChange={handleTeamSelection}
              disabled={!selectedOrg}
            >
              <option value="">Select Team</option>
              {teams.map((team) => (
                <option key={team._id} value={team._id}>
                  {team.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <button
          className="settings-button"
          onClick={() => window.open("/settings", "_blank")}
        >
          <FontAwesomeIcon icon={faCog} />
        </button>
      </div>
      <h3 className="hashtag-title">Hashtags</h3>
      <div className="hashtag-section">
        <div className="hashtag-manager-container">
          {loading && <p>Loading...</p>}
          {error && <p className="error">{error}</p>}
          {!selectedOrg || !selectedTeam ? (
            <p className="select-message">
              Please select an organization and a team to display hashtags.
            </p>
          ) : (
            <ul className="hashtag-list">
              {hashtags.length > 0 ? (
                hashtags.map((hashtag) => (
                  <li
                    key={hashtag._id}
                    className={`hashtag-item ${
                      selectedHashtags.some(
                        (selected) => selected._id === hashtag._id
                      )
                        ? "selected"
                        : ""
                    }`}
                    onClick={() => handleSelectHashtag(hashtag)}
                  >
                    {hashtag.tag}
                  </li>
                ))
              ) : (
                <p className="no-hashtags">
                  No hashtags available for the selected team.
                </p>
              )}
            </ul>
          )}
        </div>
      </div>
    </>
  );
}

export default HashtagManager;
