import React, { useState } from "react";
import SelectedHashtagsDisplay from "./sub-components/SelectedHashtagsDisplay";
import { useParams } from "react-router-dom";
function ForHashtagComponentAddItem({
  selectedHashtags,
  onItemAdded,
  setSelectedHashtags,
}) {
  const { id } = useParams();
  const [itemName, setItemName] = useState("");
  const [itemDescription, setItemDescription] = useState("");
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!itemName.trim()) newErrors.itemName = "Task name is required.";
    if (!itemDescription.trim())
      newErrors.itemDescription = "Description is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;

    const newItem = {
      name: itemName,
      description: itemDescription,
      priority: "P2 - Medium",
      status: "Open",
      hashtags: selectedHashtags.map((ht) => ht._id),
    };

    console.log("New Item being sent:", newItem);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/items`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newItem),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Error response from server:", errorData);
        throw new Error(errorData.message || "Failed to add the task");
      }

      const data = await response.json();
      console.log("Task added successfully:", data);
      setItemName("");
      setItemDescription("");
      setErrors({});
      onItemAdded(); // Trigger the parent component to refresh
    } catch (error) {
      console.error("Error adding task:", error);
      setErrors({ form: error.message });
    }
  };

  return (
    <div className="add-item-container">
      {/* <h3>Add Tasks</h3>
      {errors.form && <p className="error-message">{errors.form}</p>}
      <form onSubmit={handleSubmit} className="add-item-form">
        <div className="form">
          <input
            type="text"
            value={itemName}
            onChange={(e) => setItemName(e.target.value)}
            placeholder="New Task"
            className={errors.itemName ? "error" : ""}
          />
          {errors.itemName && <p style={{ color: "red" }}>{errors.itemName}</p>}
          <input
            type="text"
            value={itemDescription}
            onChange={(e) => setItemDescription(e.target.value)}
            placeholder="Description"
            className={errors.itemDescription ? "error" : ""}
          />
          {errors.itemDescription && (
            <p style={{ color: "red" }}>{errors.itemDescription}</p>
          )}
          <button className="button-fullwidth" type="submit">
            Add Task
          </button>
        </div>
      </form> */}

      <SelectedHashtagsDisplay
        hashtagIds={id}
        setSelectedHashtags={setSelectedHashtags}
      />
    </div>
  );
}

export default ForHashtagComponentAddItem;
