import React, { useState, useEffect } from "react";
import "../styles/ItemList.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Modal from "./Modal";

function ItemList({
  onItemClick,
  selectedItem,
  selectedHashtags = [],
  refreshTrigger,
}) {
  const [items, setItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newItemName, setNewItemName] = useState("");
  const [newItemDescription, setNewItemDescription] = useState("");
  const [errors, setErrors] = useState({});

  // Fetch items from API
  const fetchItems = async () => {
    try {
      const response = await fetch(`/api/items`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setItems(data);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };

  useEffect(() => {
    fetchItems();
  }, [refreshTrigger]);

  const handleItemClick = (item) => {
    console.log("Item clicked:", item);
    onItemClick(item);
  };

  const handleAddButtonClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setNewItemName("");
    setNewItemDescription("");
    setErrors({});
  };

  const validateForm = () => {
    const newErrors = {};
    if (!newItemName.trim()) newErrors.itemName = "Task name is required.";
    if (!newItemDescription.trim())
      newErrors.itemDescription = "Description is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAddTask = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }

    const username = localStorage.getItem("username");
    const hashtagsText =
      selectedHashtags.length > 0
        ? selectedHashtags
            .map(
              (ht) =>
                `${ht.tag} <a href='/hashtags/${ht.customId}'>(Hashtag Id: ${ht.customId})</a>`
            )
            .join(", ")
        : `Uncategorized (Hashtag ID: ${process.env.REACT_APP_UNCATEGORIZED_HASHTAG_ID})`;

    const newTask = {
      name: newItemName,
      description: newItemDescription,
      priority: "P2 - Medium",
      status: "Open",
      assignee: "unassigned",
      hashtags:
        selectedHashtags.length > 0
          ? selectedHashtags.map((ht) => ht._id)
          : [process.env.REACT_APP_UNCATEGORIZED_HASHTAG_ID],
      comments: [
        {
          text: `Tasks created with hashtags: ${hashtagsText}`,
          type: "log",
          postedBy: username,
          postedAt: new Date(),
        },
      ],
    };

    try {
      const response = await fetch(`/api/items`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newTask),
      });

      if (!response.ok) {
        throw new Error("Failed to add new task");
      }

      const data = await response.json();
      console.log("Task added successfully:", data);
      fetchItems(); // Refresh the item list
      handleModalClose(); // Close the modal
    } catch (error) {
      console.error("Error adding task:", error);
      setErrors({ form: "Failed to add the task. Please try again." });
    }
  };

  const filteredItems =
    selectedHashtags.length > 0
      ? items.filter((item) =>
          selectedHashtags.every((hashtag) =>
            item.hashtags.map((h) => h._id).includes(hashtag._id)
          )
        )
      : items;

  const getPriorityClassName = (priority) => {
    const defaultPriority = "P2 - Medium";
    const finalPriority = priority || defaultPriority;
    return `item-priority-${finalPriority.split(" ")[0]}`;
  };

  const getStatusClassName = (status) => {
    const defaultStatus = "New";
    const finalStatus = status || defaultStatus;
    switch (finalStatus) {
      case "New":
        return "item-status-new";
      case "In progress (Assigned)":
        return "item-status-in-progress";
      case "Fixed (Untested)":
        return "item-status-fixed-untested";
      case "Fixed (Tested OK)":
        return "item-status-fixed-tested";
      case "Obsolete (Not relevant)":
        return "item-status-obsolete";
      default:
        return "";
    }
  };

  return (
    <>
      <div className="backlog-header">
        <h3 className="backlog-title">Backlog</h3>
        <button className="add-item-button" onClick={handleAddButtonClick}>
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </div>

      {isModalOpen && (
        <Modal onClose={handleModalClose}>
          <div className="add-item-container">
            <h3 className="add-tasks-title">Add Task</h3>
            {errors.form && <p className="error-message">{errors.form}</p>}
            <form onSubmit={handleAddTask} className="add-item-form">
              <input
                type="text"
                value={newItemName}
                onChange={(e) => setNewItemName(e.target.value)}
                placeholder="New Task"
                className="form-inputs-description"
              />
              {errors.itemName && (
                <p className="error-message">{errors.itemName}</p>
              )}
              <textarea
                value={newItemDescription}
                type="text"
                onChange={(e) => setNewItemDescription(e.target.value)}
                placeholder="Description"
                className="descriptions"
              />
              {errors.itemDescription && (
                <p className="error-message">{errors.itemDescription}</p>
              )}
              <button type="submit" className="add-tasks-button">
                Add Task
              </button>
            </form>
          </div>
        </Modal>
      )}

      <div className="item-list-container">
        <ul className="item-list">
          {filteredItems.map((item) => {
            const priorityClass = getPriorityClassName(item.priority);
            const statusClass = getStatusClassName(item.status);

            return (
              <li
                key={item.itemId}
                onClick={() => handleItemClick(item)}
                className={`item ${
                  selectedItem && selectedItem.itemId === item.itemId
                    ? "selected"
                    : ""
                }`}
              >
                <div className={`item-card ${priorityClass} ${statusClass}`}>
                  <p className="title-tag">
                    [{item.priority ? item.priority.split(" ")[0] : "P2"}]{" "}
                    {item.name}
                  </p>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}

export default ItemList;
