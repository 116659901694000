import React, { useEffect, useState } from "react";
import axios from "axios";

const SuperAdminPage = () => {
  const [users, setUsers] = useState([]);
  const checkTokenValidity = () => {
    const token = localStorage.getItem("userToken");
    if (!token) return false;

    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    const currentTime = Date.now() / 1000;

    return decodedToken.exp > currentTime; // Returns true if token is still valid
  };
  useEffect(() => {
    const fetchUsers = async () => {
      if (!checkTokenValidity()) {
        console.error("Token expired or invalid");
        // Redirect to login or refresh the token
        return;
      }

      const token = localStorage.getItem("userToken");
      try {
        const response = await fetch("/api/users", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const data = await response.json();
        console.log("Users:", data);
        setUsers(data);
      } catch (error) {
        console.error("Failed to fetch users:", error);
      }
    };

    fetchUsers();
  }, []);

  const handleStatusChange = async (id, currentStatus) => {
    const token = localStorage.getItem("userToken");
    try {
      await axios.put(
        `http://localhost:3000/api/users/${id}/status`,
        { isActive: !currentStatus },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUsers(
        users.map((user) =>
          user._id === id ? { ...user, isActive: !currentStatus } : user
        )
      );
    } catch (error) {
      console.error("Failed to update user status:", error);
    }
  };

  return (
    <div>
      <h1>SuperAdmin Dashboard</h1>
      <table>
        <thead>
          <tr>
            <th>Username</th>
            <th>Email</th>
            <th>Role</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.length > 0 ? (
            users.map((user) => (
              <tr key={user._id}>
                <td>{user.username}</td>
                <td>{user.email}</td>
                <td>{user.role}</td>
                <td>{user.isActive ? "Active" : "Deactivated"}</td>
                <td>
                  <button
                    onClick={() => handleStatusChange(user._id, user.isActive)}
                  >
                    {user.isActive ? "Deactivate" : "Activate"}
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No users found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default SuperAdminPage;

// import React, { useEffect, useState } from "react";
// import axios from "axios";

// const SuperAdminPage = () => {
//   const [users, setUsers] = useState([]);

//   useEffect(() => {
//     const fetchUsers = async () => {
//       const token = localStorage.getItem("userToken");
//       if (!token) {
//         console.error("No token found in localStorage");
//         return;
//       }

//       try {
//         const response = await fetch("http://localhost:3000/api/users", {
//           method: "GET",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${token}`, // Ensure this line includes the correct token
//           },
//         });

//         if (!response.ok) {
//           throw new Error(`Error: ${response.status}`);
//         }

//         const data = await response.json();
//         console.log("Users:", data);
//         setUsers(data); // Update the state with the fetched users
//       } catch (error) {
//         console.error("Failed to fetch users:", error);
//       }
//     };

//     fetchUsers();
//   }, []);

//   const handleStatusChange = async (id, currentStatus) => {
//     const token = localStorage.getItem("userToken");
//     try {
//       await axios.put(
//         `http://localhost:3000/api/users/${id}/status`,
//         { isActive: !currentStatus },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       setUsers(
//         users.map((user) =>
//           user._id === id ? { ...user, isActive: !currentStatus } : user
//         )
//       );
//     } catch (error) {
//       console.error("Failed to update user status:", error);
//     }
//   };

//   return (
//     <div>
//       <h1>SuperAdmin Dashboard</h1>
//       <table>
//         <thead>
//           <tr>
//             <th>Username</th>
//             <th>Email</th>
//             <th>Role</th>
//             <th>Status</th>
//             <th>Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//           {users.length > 0 ? (
//             users.map((user) => (
//               <tr key={user._id}>
//                 <td>{user.username}</td>
//                 <td>{user.email}</td>
//                 <td>{user.role}</td>
//                 <td>{user.isActive ? "Active" : "Deactivated"}</td>
//                 <td>
//                   <button
//                     onClick={() => handleStatusChange(user._id, user.isActive)}
//                   >
//                     {user.isActive ? "Deactivate" : "Activate"}
//                   </button>
//                 </td>
//               </tr>
//             ))
//           ) : (
//             <tr>
//               <td colSpan="5">No users found</td>
//             </tr>
//           )}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default SuperAdminPage;
